jQuery(document).ready(function($) {
  //--------------------
  //  NAV
  //--------------------

  var navbtn = $(".nav-button");
  var navMenu = $(".header-content-lower");

  navbtn.on("click", function(e) {
    e.preventDefault();
    if (!navMenu.hasClass("open")) {
      navMenu.addClass("open");
    } else if (navMenu.hasClass("open")) {
      navMenu.removeClass("open");
    }
  });

  $("li.has-children > a").after('<span class="toggle-arrow"><i class="fas fa-chevron-down"></i></span>');
  $(".toggle-arrow").on("click", function() {
    console.log($(this));
    $(this)
      .toggleClass("open")
      .next()
      .slideToggle();
  });
  //--------------------
  //  BANNER
  //--------------------

  // $('.banner').slick({
  //     autoplay: true,
  //     arrows: true,
  //     dots: false,
  //     fade: true,
  //     responsive: [
  //         {
  //             breakpoint: 580,
  //             settings: {
  //                 arrows: false,
  //                 dots: true
  //             }
  //         }
  //     ]
  // });

  //--------------------
  //  OVERLAY
  //--------------------

  var popout = function(e) {
    e.preventDefault();
    $("html, body").scrollTop(100);
    $(".product-video").addClass("active");
    $(".overlay").addClass("active");
  };

  // $('#play-video').on('click', popout);
  $("#play-video").on("click", function(e) {
    e.preventDefault();
    $("html, body").scrollTop(250);
    $(".product-video").addClass("active");
    $(".overlay").addClass("active");
    console.log("ran click");
    $videoUrl = $(this).data("video_link");
    $(".product-video iframe").attr("src", $videoUrl);
  });
  $(".button--video-icon").on("click", function(e) {
    e.preventDefault();
    $("html, body").scrollTop(100);
    $(".product-video").addClass("active");
    $(".overlay").addClass("active");
    $videoUrl = $(this).data("video_link");
    console.log($videoUrl);
    $(".product-video iframe").attr("src", $videoUrl);
  });

  $(".overlay").on("click", function() {
    console.log("overlay cicked");
    $(".product-video").removeClass("active");
    $(".overlay").removeClass("active");
    $(".product-video iframe").attr("src", "");
  });

  function columnHeight() {
    if ($(window).width() > 600) {
      $(".column").each(function() {
        var colHeight = $(this).height();
        if ($(this).hasClass("column_two")) {
          $(this)
            .find(".banner__item")
            .css("min-height", colHeight / 2);
        } else {
          $(this)
            .find(".banner__item")
            .css("min-height", colHeight);
        }
      });
    } else {
      $(".banner__item").css("min-height", "auto");
    }
  }

  columnHeight();
  // JQuery matchHeight
  $(".woocommerce ul.products li.product").matchHeight();
  $(".woocommerce ul.products li.product")
    .find(".woocommerce-loop-product__title")
    .matchHeight();

  $(window).on("resize", function() {
    columnHeight();
  });
});
